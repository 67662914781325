<template>
	<div>
		<Nav @link="linkTo" :idx="2"></Nav>
		<div class="root_container">
			<div class="case">项目案例</div>
			<div class="line_block"></div>
			<div v-for="(item, i) in caseList" :key="i" class="case_con">
				<!-- PC端 隐藏 -->
				<img class="pc_hidden pro_img" :src="item.src" alt="">
				<!-- 移动端 隐藏 -->
				<img v-if="i === 1 || i === 3 || i === 5" class="pro_img mobile_hidden" :src="item.src" alt="">
				<!-- PC端 移动端都必须存在 -->
				<div class="project">
					<div class="pro_title">{{item.title}}</div>
					<div class="pro_desc">{{item.desc}}</div>
					<div v-for="(list, j) in item.label" :key="j" @mouseenter="mouseEnter(i, j)" :class="{active: j === item.defalut}" class="pro_intro">
						<div class="circle"></div>
						<div class="pro_point">{{list}}</div>
					</div>
				</div>
				<!-- 移动端 隐藏 -->
				<img v-if="i === 0 || i === 2 || i === 4" class="pro_img mobile_hidden" :src="item.src" alt="">
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'

export default {
	name: 'projectCase',
	components: {
		Nav,
		Footer
	},
	data () {
		return {
			caseList: [
				{
					defalut: 0,
					title: '杭州市“智慧养老”综合服务项目',
					desc: '2017及2020年承接2轮“智慧养老”综合服务项目。 服务于拱墅、下城、江干、滨江、钱塘5大城区共计约10万+老年人。针对传统养老服务业态进行改造升级，通过智能终端、服务中心，开展助急服务、生活服务、区域特色服务，共计3大类13项服务。',
					src: require('@/assets/img/case/login.png')
				},{
					defalut: 0,
					title: '“阳光大管家”体系建设',
					desc: '2018年年年初与拱墅区共同制定了阳光大管家体系建设，并承接“阳光大管家”综合服务平台建设项目。并且依托运营米市巷、和睦等多家阳光大管家线下站点将平台功能实际落于用户。',
					src: require('@/assets/img/case/gs.png')
				},{
					defalut: 0,
					title: '下城区“智守e家”平台',
					desc: '针对老人的不同情况定期开展电话、上门主动关怀，开展精神慰藉活动，对突发情况按照“一键直拨”的形式开展紧急呼叫，并同步提供其他有偿、低偿的养老服务。',
					src: require('@/assets/img/case/xc.png')
				},{
					defalut: 0,
					title: '江干区原居安养平台',
					desc: '“原居安养”就是在“政府主导、社会协同、以人为本、开放共享”的理念引领下，通过构建“家庭—社区—街区”三位一体式养老资源平台，已经形成家庭养老、社区托老、街区助老的三级圈层。让老人养老“离家不离社”——可以离开家庭，但不离开所在的社区以及熟悉的环境。这更贴近中国老年人的养老需求。',
					src: require('@/assets/img/case/jg.png')
				},{
					defalut: 0,
					title: '城市大脑武林街道应用场景项目',
					desc: '武林街道应用场景接入了涉老服务的各类信息，通过大数据计算与分析，向百姓和政府部门分别输出各类应用场景和管理系统的平台，以此提升企业和百姓获得感，提高政府部门工作管理效率和各类社会资源的利用效益。',
					src: require('@/assets/img/case/wl.png')
				},{
					defalut: 0,
					title: '公羊会应急救援系统',
					desc: '公羊会是在浙江省民政厅正式登记注册，具有独立社团法人资格的民间公益社团组织。严格挑选海内外员志愿者，经过培训、考核，并购置大量专业救援装备应对各种因地震、台风以及各种次生灾害造成的突发性城市应急救援。系统对公羊队参与各种救援提供了有效支撑。',
					src: require('@/assets/img/case/gy.png')
				}
			]
		}
	},
	created () {},
	mounted () {
		window.scrollTo(0, 0);
	},
	methods: {
		linkTo (routerName) {
			this.$router.push(routerName)
		},
		mouseEnter (i, j) {
			this.caseList[i].defalut = j
		},
	}
}

</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {	// 移动端
	.root_container {
		padding: 15px;
		box-sizing: border-box;
		.case {
			margin: 5px 0 20px;
			text-align: center;
			font-size: 25px;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #333333;
		}
		.line_block {
			margin: 0 auto 22px;
			width: 25px;
			height: 1px;
			background: #2890FC;
		}
		.case_con {
			.project {
				font-weight: 500;
				.pro_title {
					padding-top: 90px;
					text-align: center;
					font-size: 20px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					color: #333333;
				}
				.pro_desc {
					margin-top: 15px;
					line-height: 1.5;
					letter-spacing: 1px;
					font-size: 13px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					color: #676767;
				}
				.pro_intro {
					width: 100%;
					display: flex;
					margin: auto;
					padding: 15px;
					box-sizing: border-box;
					margin-top: 10px;
					box-shadow: 0px 0px 15px 0px rgba(217, 217, 217, 0.5);
					border-radius: 8px;
					.circle {
						width: 8px;
						height: 8px;
						margin-top: 2px;
						border: 1px solid #196FF9;
						border-radius: 50%;
					}
					.pro_point {
						margin-left: 6px;
						flex: 1;
						font-size: 14px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #676767;
					}
				}
			}
			.mobile_hidden {	// 移动端 隐藏
				display: none;
			}
			.pro_img {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 992px) {	// PC端
	.root_container {
		width: 1200px;
		margin: 0 auto 120px;
		padding: 0 15px;
		box-sizing: border-box;
		.case {
			margin: 80px 0 40px;
			text-align: center;
			font-size: 34px;
			font-family: SourceHanSansCN-Medium, SourceHanSansCN;
			font-weight: 500;
			color: #333333;
		}
		.line_block {
			margin: 0 auto 30px;
			width: 50px;
			height: 2px;
			background: #2890FC;
		}
		.case_con {
			display: flex;
			margin-top: 50px;
			.project {
				width: 390px;
				font-weight: 500;
				.pro_title {
					padding-top: 90px;
					font-size: 24px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					color: #333333;
				}
				.pro_desc {
					margin-top: 15px;
					line-height: 1.5;
					letter-spacing: 1px;
					font-size: 16px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					color: #676767;
				}
				.active {
					box-shadow: 0px 0px 17px 0px rgba(217, 217, 217, 0.5);
					border-radius: 10px;
				}
				.pro_intro {
					width: 90%;
					display: flex;
					padding: 15px;
					box-sizing: border-box;
					margin-top: 16px;
					.circle {
						width: 9px;
						height: 9px;
						border: 2px solid #196FF9;
						border-radius: 50%;
					}
					.pro_point {
						margin-left: 7px;
						flex: 1;
						font-size: 16px;
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #676767;
					}
				}
			}
			.pc_hidden {	// PC端 隐藏
				display: none;
			}
			.pro_img {
				object-fit: scale-down;
			}
		}
	}
}
</style>